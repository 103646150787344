import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"
import modernization from "../../assets/images/services-icon/application-modernization.png"
import quality from "../../assets/images/services-icon/quality.png"
import cloud from "../../assets/images/services-icon/cloud.png"
import businessProcess from "../../assets/images/services-icon/businessProcess.png"
import devops from "../../assets/images/services-icon/devops.png"
import riskManagement from "../../assets/images/services-icon/riskManagement.png"
import enterPriseApplication from "../../assets/images/services-icon/enterPriseApplication.png"
import dataScience from "../../assets/images/services-icon/dataScience.png"

import hover_modernization from "../../assets/icons/hover_application_modernization.svg"
import hover_quality from "../../assets/icons/hover_quality.svg"
import hover_cloud from "../../assets/icons/hover_cloud.svg"
import hover_businessProcess from "../../assets/icons/hover_businessProcess.svg"
import hover_devops from "../../assets/icons/hover_devops.svg"
import hover_riskManagement from "../../assets/icons/hover_riskManagement.svg"
import hover_dataScience from "../../assets/icons/hover_dataScience.svg"
import hover_enterPriseApplication from "../../assets/icons/hover_enterPriseApplication.svg"
// Services Icon

const Services = () => {
  const servicesData = [
    {
      // image: aplicationandmodernization,
      id: 1,
      image: modernization,
      hoverImage: hover_modernization,
      title: "Application and Data Modernization",
      slug: "/application-modernization",
      content:
        "Modern businesses demand agile applications. In practice, this translates to growing urgency for organizations across IT and Operations to move from legacy technologies to cloud-based solutions. Let Atyeti help you make the leap to the cloud.",
    },
    // {
    //   id: 2,
    //   image: quality,
    //   hoverImage: hover_quality,
    //   title: "Total Quality Management",
    //   slug: "/total-quality-management",
    //   content:
    //     "Atyeti is helping to adapt the new mindset of software testing and quality assurance to enable successful digital transformation. We ensure that your product is of the highest possible quality and provides a great user experience.",
    // },
    {
      id: 3,
      image: devops,
      hoverImage: hover_devops,
      title: "Cloud Infrastructure",
      slug: "/cloud-infrastructure",
      content:
        "Managing, monitoring, and optimizing cloud resources efficiently is crucial for the success and security of your business. ",
    },
    {
      id: 4,
      image: dataScience,
      hoverImage: hover_dataScience,
      title: "Data Science",
      slug: "/data-science",
      content:
       "Bringing together data and cloud technology to unlock data-driven insights and automation can be a transformative approach for enterprises. Let us help you use data to maximize your technology and AI investments."
    },
    {
      id: 5,
      image: enterPriseApplication,
      hoverImage: hover_enterPriseApplication,
      title: "Enterprise Applications",
      slug: "/enterprise-application",
      content:
        "Atyeti helps you manage systems that align with internal business applications. We have developed subject matter expertise through development, test, deployment and support services across the entire Enterprise Lifecycle.",
    },
    {
      id: 6,
      image: businessProcess,
      hoverImage: hover_businessProcess,
      title: "Business Process and Operations",
      slug: "/business-process",
      content:
      "Atyeti Business process outsourcing (BPO) services) leverages its IT-intensive business processes capability to administer and manage selected processes.Our Performance is based on defined and measurable performance metrics."
    },
  ]

  const mainServiceData=[
       {
      id: 1,
      image: cloud,
      hoverImage: hover_cloud,
      title: "Cloud Solutions",
      slug: "/cloud-solutions",
      content:
      "Atyeti can help modernize infrastructure and applications– architecting and deploying workloads across public clouds, data centers, and edge locations based on requirements specific to your digital business."
    },
    {
      id: 2,
      image: riskManagement,
      hoverImage: hover_riskManagement,
      title: "Banking and Capital Markets",
      slug: "/banking-and-capital",
      content:
      "Banking, Capital Markets and Financial institutions are challenged to address ever changing regulatory pressure, drive digital transformation, and increase competitiveness with agility and speed. Atyeti can help to achieve these goals with the assistance of our Global Markets teams across IT and Operations."
    },
  ]
  return (
    <div className="bigdata-services-area pb-80 bg-eef6fd" id="sect-services" style={{paddingBottom:"80px"}}>
  
      <div className="container">
        <div className="section-title">
          <h2>WHAT WE DO</h2>
          <div className="bar"></div>
          <p className="sub-title1">
            We have been recognized by our customers for having executed
            numerous Modernization projects. Our Global team of experts in
            Cloud, DevOps, Agile Software development and Data Science will
            accompany you during every stage of your cloud journey combining
            consulting, development, testing and migration. We are looking
            forward to our collaborative journey to the Digital Future.
          </p>
        </div>

        <div className="row">
          {mainServiceData.map(item => {
            return (
              <div
                className="col-lg-6  col-sm-12 mt-5 mb-4"
                key={item.id}
              >
                <div className="single-services-box-item">
                  <div className="icon">
                    <img src={item.image} />
                  </div>
                  <h3>
                    <Link to={item.slug}>{item.title}</Link>
                  </h3>
                  <p>{item.content} </p>

                  <Link to={item.slug} className="learn-more-btn">
                    <Icon.ArrowRight /> Learn More
                  </Link>
                </div>
              </div>
            )
          })}
        </div>

        <div className="row">
          {servicesData.map(item => {
            return (
              <div
                className="col-lg-4 col-md-6 col-sm-12 mt-5 mb-4"
                key={item.id}
              >
                <div className="single-services-box-item">
                  <div className="icon">
                    <img src={item.image} />
                  </div>
                  <h3>
                    <Link to={item.slug}>{item.title}</Link>
                  </h3>
                  <p>{item.content} </p>

                  <Link to={item.slug} className="learn-more-btn">
                    <Icon.ArrowRight /> Learn More
                  </Link>
                  {/* <div className="shape">
                    <img src={Rectangle} alt="image" />
                  </div> */}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Services
